
.image-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 277px;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}
.image-block:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.image-block:hover h3 {
  opacity: 0;
  transform: scale(0.7);

}
.image-block:hover img {
  transform: scale(1.25);
}
.image-block:hover caption {
  bottom: 0;
}
.image-block h3 {
  position: absolute;
  top: 50px;
  left: 20px;
  margin: 0;
  padding: 0;
  color: snow;
  font-size:x-large;
  font-weight: 800;
  line-height: 1;
  opacity: 1;
  transform: scale(1);
  transition: 0.25s ease;
  /* z-index: 999; */
}
.image-block img {
  height: 100%;
  transition: 0.25s;
}
.image-block caption {
  position: absolute;
  bottom: -34%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.85);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  color: white;
  line-height: 1;
  transition: 0.25s;
}
.image-block caption i {
  margin: 0 0 20px;
  padding: 0;
}
.image-block figcaption p {
  font-size: 14px;
  line-height: 1.75;
}
.image-block caption button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0;
  padding: 10px 30px;
  background-color: #1abc9c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}/*# sourceMappingURL=Slabproducts.css.map */

.slabnav{
  text-decoration: none;
  color: brown;
}

.slabnav:hover{
  color: bisque;
}