                  /* TopBar */


.topbar {
  padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #f7f7f7;
    /* padding: 1rem; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Logo styles */
  .navbar-logo {
    flex-grow: 1;
    /* margin-right: 0.5rem; */
  }
  
  .navbar-logo a {
    display: block;
  }
  
  /* Info styles */
  .navbar-info {
    flex-grow: 1;
    text-align: center;

  }
  
  .navbar-info ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }
  .mail{
    /* margin-right: 20px; */
    text-decoration: none;
    color: black;
    
  }
 
  /* Social styles */
  .navbar-social {
    flex-grow: 1;
 
  }
  
  .navbar-social ul {
    list-style: none;
    display: flex;
  }
  
  .navbar-social li {
    display: flex;
    
    /* margin-left: 10px; */
  }
  
  .navbar-social li:first-child {
    margin-left: 0;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 990px) {
    .topbar {
      flex-direction: column;
      padding: 0;
      
    }
  
    .navbar-logo,
    .navbar-info,
    .navbar-social {
      flex-basis: 100%;
      /* margin-bottom: 10px; */
      text-align: center;
      
    }
  
    .navbar-logo {
      margin-right: 0;
    }
  
   
    .mail{
      margin-right: 0px;
    }

    .timings{
      margin-left: 0px;
    }
     
  .navbar-info ul {
  margin: 0;
    padding: 0;
    list-style: none;
    display: block;
  }
  }




  

/* Mainbar */

.mainbar {
background-color: #0e0c0c;
height: 90px;
display: flex;
justify-content: center;
align-items: center;
font-size: 1.2rem;
position: sticky;
top: 0;
z-index: 20;
}

.nav-container {
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 80px;
max-width: 1500px;
}


.nav-menu {
display: flex;
list-style: none;
text-align: center;
margin-right: 2rem;
}

.nav-links{
color: #fff;
text-decoration: none;
padding: 0.5rem;
height: 100%;
border-bottom: 3px solid transparent;
}

h5{
  color: #fff;
  text-decoration: none;
  /* padding: 0.5rem 1rem; */
  height: 100%;
  /* border-bottom: 3px solid transparent; */
  }

.fa-code {
margin-left: 1rem;
}

.nav-item {
line-height: 40px;
margin-right: 1rem;
}

.nav-item:after {
content: "";
display: block;
height: 3px;
width: 0;
background: transparent;
transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
width: 100%;
background: #ffdd40;
}

.nav-item .active {
color: #ffdd40;
/* border: 1px solid #ffdd40; */
}

.nav-icon {
display: none;
}


  .mainbar .drop_navlink{
  display: inline-block;
/* padding: 0; */
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  }

  .drophead{
    color: #fff;
    text-decoration: none;
    /* padding: 0.5rem 1rem; */
    height: 100%;
    border-bottom: 3px solid transparent;
    position: relative;
    top: 7px;
  }
  
  .mainbar .drop_navlink:hover{
  background-color: #464343;
  }
  
  
  .mainbar li:hover > ul {
  display:block;
  }
  
  .dropdown {
  display: none;
  position: absolute;
  top: 70%;
  width: 12%;
  background-color: #333;
  /* padding: 5px; */
  z-index: 3;
  font-size: small;
  }

  
  
  /* .dropdown li {
  margin-bottom: 10px;
  } */
  
@media screen and (max-width: 960px) {
.nav-menu {
  display: flex;
  flex-direction: column;
  width: 70%;
  border-top: 1pxsolid #fff;
  position: absolute;
  top: 0px;
  left: -110%;
  opacity: 1;
  transition: all 0.5s ease;
  font-size: small;
  /* height: 550px; */
}

.nav-menu.active {
  background: #222020;
  left: 0px;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
}
.nav-item .active {
  color: #ffdd40;
  border: none;
}
.nav-links {
  border-bottom: 0px solid transparent;
  width: 100%;
  display: table;
  padding: 0;
}
h5 {
  border-bottom: 0px solid transparent;
  width: 100%;
  display: table;
  padding: 0;
}

.nav-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
  color: #ffdd40;
}

  .mainbar li:hover > ul {
    position: static;
    display: none;
  }
  
  
  .mainbar .drop_navlink{
    display: inline-block;
    /* padding: 8px; */
    color: #fff;
    text-decoration: none;
    /* font-weight: bold; */
    text-transform: uppercase;
    }
    
    .mainbar .drop_navlink:hover{
    background-color: #464343;
    }
    
    
    .mainbar li:hover > ul {
    display:block;
    }
    
    .dropdown {
      position: static;
    display: none;
    position: relative;
    top: 70%;
    width: 100%;
    background-color: #333;
    /* padding: 5px; */
    z-index: 3;
    font-size: 9px;
  
    }

    .drophead{
      position: relative;
      right: 14px;
      width: 100%;
      display: table;
    }
}



