.testimonial-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 5px;
  }
  
  .testimonial-carousel__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .testimonial-carousel__content {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .testimonial-carousel__author {
    font-weight: bold;
  }
  
  .testimonial-carousel__buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .testimonial-carousel__button {
    font-size: 16px;
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #020202;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .testimonial-carousel__button:hover {
    background-color: #413f3f;
  }
  
  @media screen and (min-width: 768px) {
    .testimonial-carousel {
      max-width: 768px;
    }
  }
  
  @media screen and (min-width: 992px) {
    .testimonial-carousel {
      max-width: 992px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .testimonial-carousel {
      max-width: 1200px;
    }
  }
  